<template>
  <button
    type="submit"
    class="btn btn-primary qt-loader-mini qt-loader-right"
    :class="{ 'qt-loader': loading }"
    :disabled="loading"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Card',
  props: {
    loading: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
.color-white {
  color: white;
}
</style>
